@import "../_globalColor";

html,
body {
  height: 100%;
}
#root {
  height: 100%;
  & > div {
    height: 100%;
    & > div {
      height: 100%;
    }
  }
}

.dark-mode {
  background-color: $darkBackground;
  color: white;
  transition: "0.1s";
}
